import _mapValues from "lodash/mapValues";
import _debounce from "lodash/debounce";
import _omitBy from "lodash/omitBy";

export default function(screen) {
  return {
    data() {
      return {
        defaultListener: () => {},
        searchFilterListener: null,
        shouldCallListener: true
      };
    },
    computed: {
      filters() {
        const query = this.$route.query;
        const newQuery = _mapValues(query, (v, key) => {
          if (key === "city") return v;
          const intVal = parseInt(v);
          return isNaN(intVal) ? v : intVal;
        });
        return newQuery;
      },
      pagination() {
        const limit = this.perPage;
        const skip = this.perPage * (this.currentPage - 1);
        return { skip, limit };
      },
      currentPage: {
        get() {
          return this.filters.page ?? 1;
        },
        set(val) {
          this.setFilter("page", val);
        }
      },
      perPage: {
        get() {
          return this.filters.show ?? 10;
        },
        set(val) {
          this.setFilter("show", val);
        }
      },
      totalRows: {
        get() {
          return this.$store.state.listingCount[screen];
        },
        set(count) {
          this.$store.commit("setScreenListingCount", { screen, count });
        }
      }
    },
    watch: {
      filters(newVal, oldVal) {
        this.callListener(newVal, oldVal);
      }
    },
    methods: {
      callListener({ search: newSearch }, { search: oldSearch }) {
        if (!this.shouldCallListener) return;
        if (newSearch != oldSearch && this.searchFilterListener)
          this.searchFilterListener();
        else this.defaultListener();
      },
      registerListeners(defaultListener) {
        this.defaultListener = defaultListener;
        this.searchFilterListener = _debounce(() => {
          this.defaultListener();
        }, 600);
      },
      setFilter(filter, value) {
        if (this.filters[filter] == value) return;
        if (value === "") value = null;
        const query = {
          ..._omitBy(this.filters, (value, _) => value == null),
          page: 1,
          [filter]: value ?? undefined
        };

        if (this.$route.query.tab) {
          query.tab = this.$route.query.tab;
        }

        if (this.$route.name === screen) {
          this.$router
            .replace({
              name: screen,
              query
            })
            .catch(() => {});
        }
      }
    }
  };
}
